import React from "react";
import { useState } from "react";

import { GroupRatesKidsReading } from "./arrays/RatesKidsReading";
import { IndividualRatesKidsReading } from "./arrays/RatesKidsReading";


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';

import sale from '../img/sale.svg'




function RatesReading() {

    //section "rates" switcher
    const [selectedDuration, setSelectedDuration] = useState("50min");
    const [selectedLinkDuration, setSelectedLinkDuration] = useState("50"); // Добавлено состояние для отслеживания выбранной ссылки

    // const handle45nimutesClick = () => {
    //     setSelectedDuration("45min");
    //     setSelectedLinkDuration("45"); // Обновление состояния выбранной ссылки
    // }

    // const handle60minutesClick = () => {
    //     setSelectedDuration("60min");
    //     setSelectedLinkDuration("60"); // Обновление состояния выбранной ссылки
    // }


    return (
        <div className="main__section__rates">
            <div className="main__section__rates__container">
                <h1 className="main__section__rates__container__title">Доступные программы обучения чтению:</h1>
                <div className="main__section__rates__container__packages">
                    <div className="main__section__rates__container__packages__age">
                        <div className="main__section__rates__container__packages__age__package">
                            <div className="main__section__rates__container__packages__age__package__title">Групповые занятия</div>
                            <Swiper
                                className='main__section__rates__container__packages__age__package__slider'
                                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards]}
                                effect="cards"
                                grabCursor={true}
                                slidesPerView={1}
                                // centeredSlides={true}
                                spaceBetween={10}
                                navigation
                                pagination={{ clickable: true }}
                                // autoplay={{
                                //     delay: 2500,
                                //     disableOnInteraction: false,
                                // }}
                                loop={true}
                                loopedSlides={null}
                            >
                                {GroupRatesKidsReading.map((data, key) => {
                                    return (
                                        <SwiperSlide key={key} className='main__section__rates__container__packages__age__package__slider__slide'>
                                            <div className="main__section__rates__container__packages__age__package__slider__slide__card">
                                                {/* <img loading="lazy" src={sale} alt="img" className="main__section__rates__container__packages__age__package__slider__slide__card__sale" /> */}
                                                <h3 className="main__section__rates__container__packages__age__package__slider__slide__card__h3">пакет</h3>
                                                <h2 className="main__section__rates__container__packages__age__package__slider__slide__card__title">{`"` + data.name + `"`}</h2>
                                                <p className="main__section__rates__container__packages__age__package__slider__slide__card__format">{data.format}</p>
                                                <img loading="lazy" src={data.img} alt="img" className="main__section__rates__container__packages__age__package__slider__slide__card__img" />
                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__features" >
                                                    {Object.values(data.features).map((features, index) => (
                                                        <li key={index} className="main__section__rates__container__packages__age__package__slider__slide__card__features__point" >	&#8226; {features}</li>
                                                    ))}
                                                </div>
                                                {/* <div className="main__section__rates__container__packages__age__package__slider__slide__card__oldPrice"> {data.price} рублей</div> */}
                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__price"> {data.price} рублей
                                                    <div className="main__section__rates__container__packages__age__package__slider__slide__card__price__note">
                                                        Стоимость 1 занятия ({data.duration} минут) 
                                                        {/* <span className="main__section__rates__container__packages__age__package__slider__slide__card__price__note__oldPrice">
                                                            {' ' + data.pricePerLesson} 
                                                        </span> */}
                                                        {' ' + data.pricePerLesson} руб.
                                                    </div>
                                                </div>
                                                {/* <div 
                                                    data-hystmodal='#sale'
                                                    className="main__section__rates__container__packages__age__package__slider__slide__card__infoSale"
                                                >
                                                    Подробные условия акции
                                                </div> */}
                                                {/* <form action="https://securecardpayment.ru/sc/JotKDIpeJufSTBwn" target="blank"> */}
                                                    <button className="main__section__rates__container__packages__age__package__slider__slide__card__button" data-hystmodal='#paymentform'>
                                                        Купить курс
                                                    </button>
                                                {/* </form> */}
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                        <div className="main__section__rates__container__packages__age__package">
                            <div className="main__section__rates__container__packages__age__package__title">Индивидуальные занятия</div>
                            <div className="main__section__rates__container__packages__age__package__duration">
                                {/* <button
                                    className={`main__section__rates__container__packages__age__package__duration__time ${selectedLinkDuration === "45" ? "selected" : ""
                                        }`}
                                    onClick={handle45nimutesClick}
                                >
                                    45 минут
                                </button>
                                <button
                                    className={`main__section__rates__container__packages__age__package__duration__time ${selectedLinkDuration === "60" ? "selected" : ""
                                        }`}
                                    onClick={handle60minutesClick}
                                >
                                    60 минут
                                </button> */}
                            </div>
                            {selectedDuration === "50min" && (
                                <Swiper
                                    className='main__section__rates__container__packages__age__package__slider'
                                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards]}
                                    effect="cards"
                                    grabCursor={true}
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    navigation
                                    pagination={{ clickable: true }}
                                    // autoplay={{
                                    //     delay: 2500,
                                    //     disableOnInteraction: false,
                                    // }}
                                    loop={true}
                                    loopedSlides={null}
                                >
                                    {IndividualRatesKidsReading.map((data, key) => {
                                        return (
                                            <>
                                                {Object.values(data.time["50min"]).map((time, index) => (
                                                    <SwiperSlide className='main__section__rates__container__packages__age__package__slider__slide'>
                                                        <div className="main__section__rates__container__packages__age__package__slider__slide__card">
                                                            {/* <img loading="lazy" src={sale} alt="img" className="main__section__rates__container__packages__age__package__slider__slide__card__sale" /> */}
                                                            <h3 className="main__section__rates__container__packages__age__package__slider__slide__card__h3">
                                                                пакет
                                                            </h3>
                                                            <h2 className="main__section__rates__container__packages__age__package__slider__slide__card__title">
                                                                {`"` + time.name + `"`}
                                                            </h2>
                                                            <p className="main__section__rates__container__packages__age__package__slider__slide__card__format">{time.format}</p>
                                                            <img loading="lazy" src={time.img} alt="img" className="main__section__rates__container__packages__age__package__slider__slide__card__img" />
                                                            <div className="main__section__rates__container__packages__age__package__slider__slide__card__features" >
                                                                {Object.values(time.features).map((features, index) => (
                                                                    <li key={index} className="main__section__rates__container__packages__age__package__slider__slide__card__features__point" >
                                                                        &#8226; {features}
                                                                    </li>
                                                                ))}
                                                            </div>
                                                            {/* <div className="main__section__rates__container__packages__age__package__slider__slide__card__oldPrice"> {time.price} рублей</div> */}
                                                            <div className="main__section__rates__container__packages__age__package__slider__slide__card__price"> {time.price} рублей
                                                                <div className="main__section__rates__container__packages__age__package__slider__slide__card__price__note">
                                                                    Стоимость 1 занятия ({time.duration} минут) 
                                                                    {/* <span className="main__section__rates__container__packages__age__package__slider__slide__card__price__note__oldPrice">
                                                                        {' ' + time.pricePerLesson} 
                                                                    </span> */}
                                                                    {' ' + time.pricePerLesson} руб.
                                                                </div>
                                                            </div>
                                                            {/* <div 
                                                                data-hystmodal='#sale'
                                                                className="main__section__rates__container__packages__age__package__slider__slide__card__infoSale"
                                                            >
                                                                Подробные условия акции
                                                            </div> */}
                                                            {/* <form action="https://securecardpayment.ru/sc/JotKDIpeJufSTBwn" target="blank"> */}
                                                                <button className="main__section__rates__container__packages__age__package__slider__slide__card__button" data-hystmodal='#paymentform'>
                                                                    Купить курс
                                                                </button>
                                                            {/* </form> */}
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </>
                                        );
                                    })}
                                </Swiper>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="main__section__rates__container__button" data-hystmodal='#form'>
                <button className="main__section__rates__container__button__hover">Попробовать бесплатный урок</button>
            </div>
        </div>
    )
};

export default RatesReading;