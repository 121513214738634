import React from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';

import plane1 from '../img/preschoolPlane1.svg'
import plane2 from '../img/preschoolPlane2.svg'
import item1 from '../img/reading.svg'
import item2 from '../img/writing.svg'
import item3 from '../img/math.svg'
import item4 from '../img/speech.svg'
import item5 from '../img/psyho.svg'
import item6 from '../img/world.svg'

function Programm() {
    return(
        <div className="main__section__programm">
            <img src={plane1} alt="img" className="main__section__programm__plane1"/>
            <img src={plane2} alt="img" className="main__section__programm__plane2"/>
            <h1 className="main__section__programm__title">Программа подготовки к школе включает:</h1>
            <div className="main__section__programm__container">
                <Swiper
                    className='main__section__programm__container__slider'
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    grabCursor={true}
                    // centeredSlides={true}
                    slidesPerView={1}
                    spaceBetween={10}
                    navigation
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    loopedSlides={null}
                >
                    <SwiperSlide  className='main__section__programm__container__slider__slide'>
                        <div className="main__section__programm__container__slider__slide__card">
                            <div className="main__section__programm__container__slider__slide__card__dashed">
                                <h3 className="main__section__programm__container__slider__slide__card__dashed__title">
                                    Чтение
                                </h3>
                                <img loading="lazy" src={item1} alt="img" className="main__section__programm__container__slider__slide__card__dashed__img" />
                                <p className="main__section__programm__container__slider__slide__card__dashed__text">
                                    Изучаем буквы и учимся писать их правильно, учимся правильно читать слоги и слова разной слоговой структуры, учимся читать и понимать предложения и тексты
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide  className='main__section__programm__container__slider__slide'>
                        <div className="main__section__programm__container__slider__slide__card">
                            <div className="main__section__programm__container__slider__slide__card__dashed">
                                <h3 className="main__section__programm__container__slider__slide__card__dashed__title">
                                    Письмо
                                </h3>
                                <img loading="lazy" src={item2} alt="img" className="main__section__programm__container__slider__slide__card__dashed__img" />
                                <p className="main__section__programm__container__slider__slide__card__dashed__text">
                                    Учимся правильно держать карандаш, правильно писать буквы и цифры, учимся писать слова и предложения под диктовку
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide  className='main__section__programm__container__slider__slide'>
                        <div className="main__section__programm__container__slider__slide__card">
                            <div className="main__section__programm__container__slider__slide__card__dashed">
                                <h3 className="main__section__programm__container__slider__slide__card__dashed__title">
                                    Математику
                                </h3>
                                <img loading="lazy" src={item3} alt="img" className="main__section__programm__container__slider__slide__card__dashed__img" />
                                <p className="main__section__programm__container__slider__slide__card__dashed__text">
                                    Запоминаем образ цифр, учимся соотносить цифру с количеством, изучаем прямой и обратный счёт, учим состав числа, учимся решать примеры на сложение и вычитание, составляем задачи по картинке и решаем их, знакомимся с основами геометрии, учимся работать в клетке, развиваем логическое мышление
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide  className='main__section__programm__container__slider__slide'>
                        <div className="main__section__programm__container__slider__slide__card">
                            <div className="main__section__programm__container__slider__slide__card__dashed">
                                <h3 className="main__section__programm__container__slider__slide__card__dashed__title">
                                    Развитие речи
                                </h3>
                                <img loading="lazy" src={item4} alt="img" className="main__section__programm__container__slider__slide__card__dashed__img" />
                                <p className="main__section__programm__container__slider__slide__card__dashed__text">
                                    Развиваем навыки связной речи и расширяем словарный запас, учимся составлять рассказ по картинке и пересказывать текст, учимся составлять описательные рассказы и развёрнуто отвечать на вопросы
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide  className='main__section__programm__container__slider__slide'>
                        <div className="main__section__programm__container__slider__slide__card">
                            <div className="main__section__programm__container__slider__slide__card__dashed">
                                <h3 className="main__section__programm__container__slider__slide__card__dashed__title">
                                    Развитие психических процессов
                                </h3>
                                <img loading="lazy" src={item5} alt="img" className="main__section__programm__container__slider__slide__card__dashed__img" />
                                <p className="main__section__programm__container__slider__slide__card__dashed__text">
                                    Развиваем все виды памяти, развиваем умение концентрировать и переключать внимание, учимся обобщать и выделять лишнее, выстраивать связи, выявлять и понимать закономерности
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide  className='main__section__programm__container__slider__slide'>
                        <div className="main__section__programm__container__slider__slide__card">
                            <div className="main__section__programm__container__slider__slide__card__dashed">
                                <h3 className="main__section__programm__container__slider__slide__card__dashed__title">
                                    Окружающий мир 
                                </h3>
                                <img loading="lazy" src={item6} alt="img" className="main__section__programm__container__slider__slide__card__dashed__img" />
                                <p className="main__section__programm__container__slider__slide__card__dashed__text">
                                    Формируем основное представление об окружающем мире, работаем со всеми основными лексическими темами
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default Programm;