import React from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

import point from '../img/point.svg'
import resultsImg from '../img/results.svg'
import wavesKids2 from '../img/wavesKids2.svg'

import plane1 from '../img/preschoolPlane1.svg'
import plane2 from '../img/preschoolPlane2.svg'




function About() {
    return (
        <div className="main__section__results">
            <div className="main__section__results__container">
                <h2 className="main__section__results__container__title">
                    Какие результаты получит Ваш ребёнок:
                </h2>
                <div className="main__section__results__container__content">
                    <div className="main__section__results__container__content__points">
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Овладеет основными учебными навыками: чтение, письмо, математика
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Научится составлять предложения, делить слова на слоги, ставить ударение, делать звуковой анализ слов и работать с текстом
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Научится работать с числовым лучом, решать примеры и понимать структуру задачи
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Научится ориентироваться на листе и писать графические диктанты
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Сможет правильно составить рассказ по картинке
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Расширит словарный запас по основным лексическим темам
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Научится слушать и слышать учителя
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Научится работать самостоятельно
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Получит представление о формате школьного урока
                            </h2>
                        </div>
                    </div>
                    <img loading="lazy" src={resultsImg} alt="img" className="main__section__results__container__content__img" />
                </div>
                <div className="main__section__results__container__button" data-hystmodal='#form'>
                    <button className="main__section__results__container__button__hover">Попробовать бесплатный урок</button>
                </div>
            </div>
        </div>
    )
}

export default About;