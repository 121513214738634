import rate1 from '../../img/rate1.svg'
import rate2 from '../../img/rate2.svg'
import rate3 from '../../img/rate3.svg'
import rate4 from '../../img/rate4.svg'
import rate5 from '../../img/rate5.svg'
import rate6 from '../../img/rate6.svg'

export const GroupRatesKidsReading = [
    {
        id: 1,
        name: 'Стандартный',
        format: 'Групповые занятия',
        img: rate2,
        features: [
            '8 занятий по 50 минут',
            'занятия 2 раза в неделю по расписанию',
            'занятия должны быть использованы в течение 4 недель',
            'неиспользованные занятия не переносятся и не восстанавливаются',
        ],
        price: 3680,
        sale: 2960,
        duration: 50,
        count: 8,
        pricePerLesson: 460,
        salePerLesson: 370,
    },
];

export const IndividualRatesKidsReading = [
    {
        "time": {
            "50min": [
                {
                    id: 1,
                    name: 'Минимальный',
                    format: 'Индивидуальные занятия',
                    img: rate1,
                    features: [
                        '4 занятия по 50 минут',
                        'график занятий: ЛЮБОЙ без ограничений',
                        'занятие можно отменить/перенести, уведомив об этом минимум за 3 часа до начала занятия',
                        'максимальный срок использования абонемента - 6 недель с первого занятия',
                    ],
                    price: 3800,
                    sale: 3200,
                    duration: 50,
                    count: 4,
                    pricePerLesson: 950,
                    salePerLesson: 800,
                },
                {
                    id: 2,
                    name: 'Стандартный',
                    format: 'Индивидуальные занятия',
                    img: rate2,
                    features: [
                        '8 занятий по 50 минут',
                        'график занятий: НЕ РЕЖЕ, чем 2 занятия в неделю',
                        'занятие можно отменить/перенести, уведомив об этом минимум за 3 часа до начала занятия',
                        'максимальный срок использования абонемента - 6 недель с первого занятия',
                    ],
                    price: 7120,
                    duration: 50,
                    count: 8,
                    pricePerLesson: 890,
                    sale: 6000,
                    salePerLesson: 750,
                },
                {
                    id: 3,
                    name: 'Оптимальный',
                    format: 'Индивидуальные занятия',
                    img: rate4,
                    features: [
                        '12 занятий по 50 минут',
                        'график занятий: НЕ РЕЖЕ, чем 2 занятия в неделю',
                        'занятие можно отменить/перенести, уведомив об этом минимум за 3 часа до начала занятия',
                        'максимальный срок использования абонемента - 8 недель с первого занятия',
                    ],
                    price: 10440,
                    duration: 50,
                    count: 12,
                    pricePerLesson: 870,
                    sale: 8760,
                    salePerLesson: 730,
                },
                {
                    id: 4,
                    name: 'Выгодный',
                    format: 'Индивидуальные занятия',
                    img: rate3,
                    features: [
                        '16 занятий по 50 минут',
                        'график занятий: НЕ РЕЖЕ, чем 3 занятия в неделю',
                        'занятие можно отменить/перенести, уведомив об этом минимум за 3 часа до начала занятия',
                        'максимальный срок использования абонемента - 8 недель с первого занятия',
                    ],
                    price: 13760,
                    duration: 50,
                    count: 16,
                    pricePerLesson: 860,
                    sale: 11520,
                    salePerLesson: 720,
                }
            ],
            // "60min": [
            //     {
            //         id: 1,
            //         name: 'Минимальный',
            //         format: 'Индивидуальные занятия',
            //         img: rate1,
            //         features: [
            //             '4 занятия по 60 минут',
            //             'график занятий: ЛЮБОЙ без ограничений',
            //             'занятие можно отменить/перенести, уведомив об этом минимум за 3 часа до начала занятия',
            //             'максимальный срок использования абонемента - 6 недель с первого занятия',
            //         ],
            //         price: 5080,
            //         duration: 60,
            //         count: 4,
            //         pricePerLesson: 1270,
            //         sale: 4280,
            //         salePerLesson: 1070,
            //     },
            //     {
            //         id: 2,
            //         name: 'Стандартный',
            //         format: 'Индивидуальные занятия',
            //         img: rate2,
            //         features: [
            //             '8 занятий по 60 минут',
            //             'график занятий: НЕ РЕЖЕ, чем 2 занятия в неделю',
            //             'занятие можно отменить/перенести, уведомив об этом минимум за 3 часа до начала занятия',
            //             'максимальный срок использования абонемента - 6 недель с первого занятия',
            //         ],
            //         price: 9040,
            //         duration: 60,
            //         count: 8,
            //         pricePerLesson: 1130,
            //         sale: 8000,
            //         salePerLesson: 1000,
            //     },
            //     {
            //         id: 3,
            //         name: 'Оптимальный',
            //         format: 'Индивидуальные занятия',
            //         img: rate4,
            //         features: [
            //             '12 занятий по 60 минут',
            //             'график занятий: НЕ РЕЖЕ, чем 2 занятия в неделю',
            //             'занятие можно отменить/перенести, уведомив об этом минимум за 3 часа до начала занятия',
            //             'максимальный срок использования абонемента - 8 недель с первого занятия',
            //         ],
            //         price: 13320,
            //         duration: 60,
            //         count: 12,
            //         pricePerLesson: 1110,
            //         sale: 11700,
            //         salePerLesson: 975,
            //     },
            //     {
            //         id: 4,
            //         name: 'Выгодный',
            //         format: 'Индивидуальные занятия',
            //         img: rate3,
            //         features: [
            //             '16 занятий по 60 минут',
            //             'график занятий: НЕ РЕЖЕ, чем 3 занятия в неделю',
            //             'занятие можно отменить/перенести, уведомив об этом минимум за 3 часа до начала занятия',
            //             'максимальный срок использования абонемента - 8 недель с первого занятия',
            //         ],
            //         price: 17520,
            //         duration: 60,
            //         count: 16,
            //         pricePerLesson: 1095,
            //         sale: 15360,
            //         salePerLesson: 960,
            //     }
            // ]
        }
    }
]