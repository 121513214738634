import React from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

import point from '../img/point.svg'
import resultsImg from '../img/results.svg'
import wavesKids2 from '../img/wavesKids2.svg'

import plane1 from '../img/preschoolPlane1.svg'
import plane2 from '../img/preschoolPlane2.svg'




function AboutReading() {
    return (
        <div className="main__section__results">
            <div className="main__section__results__container">
                <h2 className="main__section__results__container__title">
                    Какие результаты получит Ваш ребёнок:
                </h2>
                <div className="main__section__results__container__content">
                    <div className="main__section__results__container__content__points">
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Выучит буквы, научится читать слоги и слова разной слоговой структуры
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Овладеет правильной техникой чтения
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Научится читать предложения и понимать прочитанное
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Овладеет звуко-буквенным  анализом
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Научится понимать структуру слова и предложения и составлять схемы к ним
                            </h2>
                        </div>
                        <div className="main__section__results__container__content__points__point">
                            <img loading="lazy" src={point} alt="img" className="main__section__results__container__content__points__point__img" />
                            <h2 className="main__section__results__container__content__points__point__text">
                                Научится ставить ударение в словах
                            </h2>
                        </div>
                    </div>
                    <img loading="lazy" src={resultsImg} alt="img" className="main__section__results__container__content__img" />
                </div>
                <div className="main__section__results__container__button" data-hystmodal='#form'>
                    <button className="main__section__results__container__button__hover">Попробовать бесплатный урок</button>
                </div>
            </div>
        </div>
    )
}

export default AboutReading;