import teacher1 from '../../img/teachers/teacher-1.webp'
import teacher2 from '../../img/teachers/teacher-2.webp'
import teacher3 from '../../img/teachers/teacher-3.webp'
import teacher4 from '../../img/teachers/teacher-4.webp'


export const TeacherInfo = [
    {
        id: 1,
        modal: 'teacher1',
        name: 'Сидорова Ирина Леонидовна',
        img: teacher1,
        post: 'Руководитель онлайн школы «Shine School»',
        info: [
            'Меня зовут Сидорова Ирина, и я руководитель онлайн школы английского языка «Shine School».', 
            'Наша школа создана для того, чтобы сделать обучение английскому языку удобным, доступным и интересным. Мы работаем с детьми и взрослыми и твердо уверены, что нет людей, неспособных к языкам!', 
            'Успех нашей онлайн школы состоит из следующих компонентов:',
            '· профессиональные преподаватели, получающие удовольствие от своей работы,',
            '· «Живое» общения преподавателя и группы,',
            '· уникальная методика обучения, проверенная долгим опытом (более 15 лет офлайн и онлайн работы),',
            '· аутентичные учебные пособия,',
            '· использование современных технологий (мультимедиа, электронные учебные пособия и т.д.),',
            '· демократичные цены и возможность выбора удобного пакета оплаты.',
            'Мы твердо уверены, что онлайн обучение может быть качественным, результативным и доступным! Хотите в этом убедится? Присоединяйтесь к нам!',
        ],
    },
    {
        id: 2,
        modal: 'teacher2',
        name: 'Кан Ирина Олеговна',
        img: teacher2,
        post: 'Aкадемический директор, методист онлайн школы «Shine School»',
        info: [
            'Я преподаю английский язык уже почти 20 лет и нежно люблю свою профессию. За это время я работала с учениками разного возраста и в разном формате: в вузе со студентами, с детьми от 5 лет в частной школе, с подростками и взрослым в языковых центрах и онлайн.', 
            'Мой профиль — подготовка к международным экзаменам и ЕГЭ. Мои ученики успешно сдают кембриджские экзамены (PET, FCE, Business English Certificate), а также ЕГЭ на 80+. Не передать словами мою радость, когда мои ученики добиваются таких высоких результатов!', 
            'Имею диплом преподавателя английского языка с отличием по специальности лингвистика, сертификаты международных экзаменов TOEFL, TOEIC С1(Advanced).',
            'Прошла курс обучения в языковой школе в Лондоне, участница American English E-Teacher Program. Прохожу международную сертификацию по методике преподавания TESOL (Arizona State University). Очень люблю учиться и учить онлайн и по опыту знаю, что каждый может достичь своей цели с помощью онлайн-обучения и открыть для себя мир без границ!',
        ]
    },
    {
        id: 3,
        modal: 'teacher3',
        name: 'Ольга Александровна',
        img: teacher3,
        post: 'Педагог онлайн школы «Shine School',
        info: [
            'Стаж работы с детьми более 10 лет. Имею высшее педагогическое образование . ', 
            'Постоянно совершенствую свои навыки, ищу и осваиваю новые современные подходы и методы в обучении. Стараюсь найти подход к любому ученику и передать детям не только знания, но и любовь к обучению. Я влюблена в дело, которое делаю и в детей, с которыми работаю.',
        ]
    },
    {
        id: 4,
        modal: 'teacher4',
        name: 'Анна Игорьевна',
        img: teacher4,
        post: 'Педагог онлайн школы «Shine School',
        info: [
            'Мой педагогический стаж 8 лет. У меня высшее педагогическое образование,и я стараюсь постоянно пополнять свои знания. Я очень люблю дело, которым занимаюсь и ценю в каждом своём ученике личность.', 
            'Всегда нахожу в ученике сильную сторону и много внимания уделяю мотивации. Максимально доступно и интересно планирую занятия,ориентируясь на особенности ребёнка. Работаю с учётом нейропсихологического подхода.',
        ]
    },
];