import React from 'react';


function Contract() {
    return (
        <>
            <div
                data-hystmodal='#Contract'
                className="footer__content__left__links__link"
            >
                Договор оферта
            </div>
            <div className="main__choice hystmodal" id='Contract' aria-hidden="true">
                <div className="main__docs__modal hystmodal__wrap">
                    <div className="main__docs__modal__layout hystmodal__window window__teacher" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <div className="main__docs__modal__layout__content">
                            <h1 className="main__docs__modal__layout__content__title">Договор оферта</h1>
                            <p className="main__docs__modal__layout__content__second">
                                1.1. Данный документ является официальным предложением (публичной Офертой) Индивидуального предпринимателя Сидоровой Ирины Леонидовны (ИНН: 550311058224, ОГРНИП:322554300055780 (в дальнейшем – Исполнитель) и содержит все существенные условия договора по оказанию услуг по проведению дистанционных занятий по дополнительному образованию детей, вебинаров, мастер-классов (в дальнейшем – Услуги), предлагаемых любому физическому или юридическому лицу, именуемому в дальнейшем «Заказчик».
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                1.2. В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации (ГК РФ) в случае принятия изложенных ниже условий, Заказчик, производящий акцепт этой Оферты (далее Заказчик) считается заключившим с Исполнителем договор на условиях, изложенных в настоящей Оферте, в соответствии с пунктом 3 статьи 438 ГК РФ
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                1.3. Учитывая особый порядок заключения договора оказания услуг, Исполнитель уведомляет Заказчика о необходимости до момента осуществления действий по акцепту настоящей публичной оферты внимательно ознакомиться с текстом настоящей публичной оферты (включая все приложения). Если Заказчик  не согласен с какими-либо условиями настоящей Оферты, Вы вправе не акцептировать настоящую Оферту. Акцепт настоящей публичной Оферты на иных условиях не допускается. В случае возникновения споров, распечатанный текст настоящей оферты и документы, подтверждающие оплату, будут являться доказательствами заключения договора на оказание услуг между Исполнителем и Заказчиком на условиях, изложенных в настоящей оферте.
                            </p>
                            <h3 className="main__docs__modal__layout__content__first">
                                2. Термины и определения.
                            </h3>
                            <p className="main__docs__modal__layout__content__second">
                                2.1. В настоящей публичной Оферте нижеприведенные термины используются в следующем значении:
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                2.1.1 Оферта – предложение юридическим и физическим лицам (Заказчикам) заключить с Исполнителем «Договор на оказание услуг по проведению дистанционных занятий по дополнительному образованию детей, вебинаров, мастер-классов» (далее по тексту «Договор»), на условиях, изложенных в данном предложении и размещенное на сайте Исполнителя : https://kids.shineschool.ru/
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                2.1.2. «Пакет услуг» — систематизированный объем услуг, условия, форма и период их оказания, а также стоимость; «Пакеты услуг» доступны для ознакомления и выбора Заказчика на сайте  Исполнителя (https://kids.shineschool.ru/).
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                2.1.3. Акцепт Оферты – полное и безоговорочное принятие Оферты Заказчиком, путем  выбора и оплаты соответствующего «Пакета услуг». Акцепт Оферты означает заключение договора Оферты между Сторонами в соответствии с выбранным пакетом услуг;
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                2.1.4. Занятие – время, в течение которого Исполнителем проводится Занятие с Заказчиком.
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                2.1.5. Специалист  – представитель Исполнителя, имеющий соответствующую квалификацию, непосредственно проводящий занятие с Заказчиком.
                            </p>
                            <h3 className="main__docs__modal__layout__content__first">
                                3. Предмет оферты.
                            </h3>
                            <p className="main__docs__modal__layout__content__second">
                                3.1. Предметом настоящей Оферты является оказание Заказчику услуг по проведению занятий по дополнительному образованию детей, вебинаров, мастер-классов онлайн с помощью программного обеспечения, выбранного по усмотрению Исполнителя, в соответствии с условиями  настоящей Оферты и выбранным и оплаченным Заказчиком «Пакетом услуг», действующим у Исполнителя.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                3.2. Исполнитель имеет право в любой момент изменять содержание любого «Пакета услуг» и условия настоящей публичной Оферты в одностороннем порядке без предварительного согласования с Заказчиком, обеспечивая при этом публикацию измененных условий в сети Интернет по адресуhttps://kids.shineschool.ru/. Измененные условия вступают в силу с момента их публикации.
                            </p>
                            <h3 className="main__docs__modal__layout__content__first">
                                4. Права и обязанности сторон.
                            </h3>
                            <p className="main__docs__modal__layout__content__second">
                                4.1. Исполнитель обязуется:
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                4.1.1. В согласованные Сторонами сроки оказать услуги Заказчику надлежащим образом, в соответствии с условиями Договора, заключенного посредством акцепта настоящей оферты   и выбранным «пакетом услуг».
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                4.1.2. Не разглашать конфиденциальную информацию и данные, предоставленные Заказчиком в связи с исполнением заключенного Договора.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                4.2. Заказчик имеет право:
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                4.2.1. Требовать от Исполнителя своевременного и полного оказания услуг в соответствии с условиями заключенного Договора.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                4.3. Заказчик обязуется:
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                4.3.1 Своевременно и полностью оплатить Исполнителю стоимость оказываемых услуг в порядке, в сроки и размере, установленными настоящей Офертой и условиями выбранного им «Пакета услуг».
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                4.3.2. Предоставить Исполнителю все сведения и данные, необходимые для выполнения своих обязательств по заключенному Договору.
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                4.3.3. Не разглашать конфиденциальную информацию и иные данные, предоставленные Исполнителем в связи с исполнением настоящей Оферты, не раскрывать и не разглашать такие факты или информацию (кроме информации общедоступного характера) какой-либо третьей стороне без предварительного письменного согласия Исполнителя.
                            </p>
                            <p className="main__docs__modal__layout__content__third">
                                4.3.4. Присутствовать на всех согласованных Занятиях.
                            </p>
                            <h3 className="main__docs__modal__layout__content__first">
                                5. Порядок оказания услуг.
                            </h3>
                            <p className="main__docs__modal__layout__content__second">
                                5.1. Акцепт оферты Заказчиком возможен только после предварительного подтверждения Исполнителем возможности оказания услуг по выбранному Заказчиком «Пакету услуг» и в запрошенное Заказчиком время (период). С целью подтверждения такой возможности Заказчик до момента оплаты связывается с Исполнителем по телефону  или иным средствам связи, указанным на сайте  https://kids.shineschool.ru/. Окончательный акцепт оферты (оплата соответствующего «пакета услуг»)  производится Заказчиком после получения от Исполнителя указанного выше соответствующего подтверждения и согласования сторонами времени (графика).
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                5.2. Исполнитель предоставляет Заказчику необходимую информацию для занятий онлайн с использованием программного обеспечения. Программное обеспечение для проведения занятий выбирается по усмотрению Исполнителя.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                5.3. В соответствии с согласованным Сторонами графиком, Специалист, представляющий Исполнителя, связывается с Заказчиком и проводит Занятие.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                5.4. Заказчик может отменить или перенести занятие, если такое право указано в выбранном им «Пакете услуг» и  на условиях, указанных в таком Пакете. Для переноса/ отмены занятия Заказчик обязан связаться с Исполнителем по телефону, указанному в реквизитах Исполнителя в тексте настоящей оферты или по телефону, указанному  на сайте https://kids.shineschool.ru/.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                5.5. Если право на перенос / отмену занятия в Прайс-листе на услуги отсутствует и/или не были соблюдены условия, на которых занятие переносится / отменяется, то Занятие считается состоявшимся и оплачивается Заказчиком в размере 100%.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                5.6. Если в установленное графиком время Заказчик не выходит на Занятие, Специалист в течение 5 минут ожидает появления Заказчика. Если в результате связаться с Заказчиком не удается, Занятие считается пропущенным по вине Заказчика, не переносится на другое время и оплачивается Заказчиком в размере 100% в соответствии с п. 2. ст. 781 ГК РФ
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                5.7. Занятия, которые не состоялись по вине Исполнителя, переносятся без их потери на другое время по согласованию с Заказчиком.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                                5.8.  Исполнитель в любое время  вправе произвести Заказчику  замену одного  Специалиста  на другого. Согласие на это Заказчика не требуется.
                            </p>
                            <h3 className="main__docs__modal__layout__content__first">
                                6. Оплата услуг.
                            </h3>
                            <p className="main__docs__modal__layout__content__second">
                            6.1. Стоимость услуг определяется согласно выбранного Заказчиком «Пакета услуг»
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                            6.2. Тарифы в «Пакете услуг» представлены без учета комиссионного сбора, устанавливаемого компаниями кредитных карт, терминалами оплаты и интернет провайдерами.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                            6.3. Заказчик оплачивает услуги в виде 100% предоплаты от стоимости, указанной в соответствующем «Пакете услуг», в срок не позднее, чем за два дня до первого занятия в соответствии с выбранным пакетом.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                            6.4. Заказчик оплачивает услуги путем перечисления денежных средств на расчетный счет Исполнителя через сервис на сайте https://kids.shineschool.ru/ или иным способом по согласованию с Исполнителем.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                            6.5. Датой исполнения обязательств по перечислению денежных средств, считается дата поступления денежных средств на расчетный счет Исполнителя.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                            6.6. В случае отказа Заказчиком от обучения по причине невыполнения Исполнителем обязательств по данному Договору, внесенная в качестве оплаты за курс сумма подлежит частичному возврату с учетом перерасчета проведенных Исполнителем занятий.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                            6.7. В случае досрочного расторжения Заказчиком настоящего Договора, возврат денежных средств осуществляется Заказчику за вычетом стоимости проведенных занятий, суммы комиссии за возврат денежных средств Заказчику, а также за вычетом из суммы, подлежащей возврату суммы штрафных санкций за досрочное расторжение в размере  20% от оплаченной суммы и иных понесенных Исполнителем расходов. Способ возврата денежных средств выбирается по согласованию Сторон.
                            </p>
                            <h3 className="main__docs__modal__layout__content__first">
                                7. Ответственность сторон.
                            </h3>
                            <p className="main__docs__modal__layout__content__second">
                            7.1. За неисполнение или ненадлежащее исполнение обязательств по настоящему Договору Стороны несут ответственность в соответствии с действующим законодательством РФ.
                            </p>
                            <p className="main__docs__modal__layout__content__second">
                            7.2. Стороны принимают все необходимые меры для разрешения разногласий, возникших в процессе исполнения условий Оферты, путем переговоров.
                            </p>
                            <h3 className="main__docs__modal__layout__content__first">
                            8. Основания расторжения договора
                            </h3>
                            <p className="main__docs__modal__layout__content__second">
                            8.1. Настоящий договор, может быть, расторгнут по соглашению сторон. По инициативе одной из сторон, договор, может быть, расторгнут по основаниям, предусмотренным действующим законодательством Российской Федерации.
                            </p>
                            <h3 className="main__docs__modal__layout__content__first">
                            9. Ответственность за неисполнение или ненадлежащее исполнение обязательств по настоящему договору
                            </h3>
                            <p className="main__docs__modal__layout__content__second">
                            9.1. В случае неисполнения или ненадлежащего исполнения сторонами обязательств по настоящему договору они несут ответственность, предусмотренную гражданским законодательством и законодательством о защите прав потребителей (в случае если это применимо к правоотношениям сторон) на условиях, установленных этим законодательством.
                            </p>
                            <h3 className="main__docs__modal__layout__content__first">
                            10. Реквизиты Исполнителя.
                            </h3>
                            <p className="main__docs__modal__layout__content__second">
                            Индивидуальный предприниматель Сидорова Ирина Леонидовна <br/>
                            Адрес: 644074, Омская область, г. Омск, ул. Ватутина, д.22А, кв. 43 <br/>
                            Адрес для почтовых отправлений:<br/>
                            644074, Омская область, г. Омск, ул. Ватутина, д.22А, кв. 43<br/>
                            ИНН 550311058224<br/>
                            ОГРНИП 322554300055780<br/>
                            Р/с 40802810045000100179<br/>
                            К/с 30101810900000000673<br/>
                            БИК  045209673
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contract;