import React from "react";
import { useState } from "react";

import LeadForm from "./form";
import PreparingForSchool from "./preparingForSchool";
import PreparingForSchoolReading from "./PreparingForSchoolReading";
import Programm from "./programm";
import Advantages from "./Agvantages";

import About from "./About";
import AboutReading from "./AboutReading";
import Rates from "./Rates";
import RatesReading from "./RatesReading";
import TeacherSlider from "./TeachersSlider";
import DiplomaSlider from "./DiplomasSlider";
import Comments from "./VkComments";
import Questions from "./Questions";
import Sales from "./sales";
import PaymentForm from "./payForm";


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';

import HystModal from "hystmodal";
import '../../node_modules/hystmodal/dist/hystmodal.min.css'

// import waves from '../img/waves.svg'
// import waves5 from '../img/waves5.svg'
// import china from '../img/chinaicon.svg'
// import gradient from '../img/gradient.svg'

import greet1 from '../img/greet1.svg'
import greet2 from '../img/greet2.svg'
import greetPlane1 from '../img/plane1.svg'
import greetPlane2 from '../img/plane2.svg'
import wavesKids from '../img/wavesKids.svg'
import wavesKids2 from '../img/wavesKids2.svg'
import sale from '../img/sale.svg'
import up from '../img/up.svg'




function Main() {

    const myModal = new HystModal({
        linkAttributeName: "data-hystmodal",
        // настройки (не обязательно), см. API
    });

    const [selectedProfile, setSelectedProfile] = useState("preSchool");
    const [selectedButton, setSelectedButton] = useState("preSchoolButton"); // Добавлено состояние для отслеживания выбранной ссылки

    const handlePreSchoolClick = () => {
        setSelectedProfile("preSchool");
        setSelectedButton("preSchoolButton"); // Обновление состояния выбранной ссылки
    }

    const handleReadingClick = () => {
        setSelectedProfile("reading");
        setSelectedButton("readingButton"); // Обновление состояния выбранной ссылки
    }

    const handleUpClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Добавляем плавное скроллирование
        });
    }

    return (
        <main className="main">
            <img src={up} onClick={handleUpClick} alt="logo" className="main__up" />
            <div className="main__choice hystmodal" id='profile' aria-hidden="true">
                <div className="main__choice__modal hystmodal__wrap">
                    <div className="main__choice__modal__layout hystmodal__window window__teacher" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <div className="main__choice__modal__layout__content">
                            <h1 className="main__choice__modal__layout__content__title">Выбрать другой профиль обучения:</h1>
                            <a href="https://my-english.club/" target="blank" className="main__choice__modal__layout__content__a">Я хочу изучать английский язык</a>
                            <a href="https://chinese.shineschool.ru/" target="blank" className="main__choice__modal__layout__content__a">Я хочу изучать китайский язык</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main__section__form hystmodal" id='paymentform' aria-hidden="true">
                <div className="hystmodal__wrap">
                    <div className="hystmodal__window" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <PaymentForm/>
                    </div>
                </div>
            </div>
            <Sales />
            <section className="main__section">
                <div className="main__section__greeting">
                    <img src={greetPlane1} alt="img" className="main__section__greeting__greetPlane1" />
                    <img src={greetPlane2} alt="img" className="main__section__greeting__greetPlane2" />
                    <img src={greet1} alt="img" className="main__section__greeting__greet1" />
                    <img src={greet2} alt="img" className="main__section__greeting__greet2" />
                    <div className="main__section__greeting__content">
                        <a href="tel: +79238281981" className="main__section__greeting__content__number">
                            8-800-550-21-34
                        </a>
                        <div className="main__section__greeting__content__left">
                            <h1 className="main__section__greeting__content__left__h1">
                                Подготовка к школе и обучение чтению, не выходя из дома
                            </h1>
                            <h1 className="main__section__greeting__content__left__p">
                                Занятия онлайн от 430 руб
                            </h1>
                            <div className="main__section__greeting__content__left__button" data-hystmodal='#form'>
                                <button className="main__section__greeting__content__left__button__hover">Бесплатное <br /> пробное занятие</button>
                            </div>
                            <div className="main__section__greeting__content__left__buttons">
                                <div className="main__section__greeting__content__left__buttons__button">
                                    <button
                                        className={`main__section__greeting__content__left__buttons__button__hover ${selectedButton === "preSchoolButton" ? "selected" : ""}`}
                                        onClick={handlePreSchoolClick}
                                    >
                                        <a href="#school"
                                            className="main__section__greeting__content__left__buttons__button__hover__link"
                                        >
                                            Меня интересует подготовка к школе
                                        </a>
                                    </button>
                                </div>
                                <div className="main__section__greeting__content__left__buttons__button">
                                    <button
                                        className={`main__section__greeting__content__left__buttons__button__hover ${selectedButton === "readingButton" ? "selected" : ""}`}
                                        onClick={handleReadingClick}
                                    >
                                        <a href="#reading"
                                            className="main__section__greeting__content__left__buttons__button__hover__link"
                                        >
                                            Меня интересует обучение чтению
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__section__form hystmodal" id='form' aria-hidden="true">
                    <div className="hystmodal__wrap">
                        <div className="hystmodal__window" role="dialog" aria-modal="true">
                            <button data-hystclose className="hystmodal__close">Закрыть</button>
                            <LeadForm />
                        </div>
                    </div>
                </div>
                <img src={wavesKids} alt="img" className="main__section__waves" />
            </section>
            <section className="main__section">
                {selectedProfile === "preSchool" && (
                    <PreparingForSchool />
                )}
                {selectedProfile === "reading" && (
                    <PreparingForSchoolReading />
                )}
                <img src={wavesKids2} alt="img" className="main__section__waves" />
            </section>
            <section className="main__section">
                {selectedProfile === "preSchool" && (
                    <Programm />
                )}
                {selectedProfile === "reading" && (
                    ''
                )}
            </section>
            <section className="main__section">
                {selectedProfile === "preSchool" && (
                    <About />
                )}
                {selectedProfile === "reading" && (
                    <AboutReading />
                )}
                <img src={wavesKids} alt="img" className="main__section__waves" />
            </section>
            <section className="main__section" id="rates">
                <div className="main__choice hystmodal" id='sale' aria-hidden="true">
                    <div className="main__choice__modal hystmodal__wrap">
                        <div className="main__choice__modal__layout hystmodal__window window__teacher" role="dialog" aria-modal="true">
                            <img loading="lazy" src={sale} alt="img" className="main__section__rates__container__packages__age__package__slider__slide__card__sale" />
                            <button data-hystclose className="hystmodal__close">Закрыть</button>
                            <div className="main__choice__modal__layout__content">
                                <h1 className="main__choice__modal__layout__content__title">Особое предложение:</h1>
                                <p className="main__choice__modal__layout__content__p">
                                    Если вы принимаете решение о приобретении первого абонемента в течение октября, то мы рады сообщить, что данная цена будет действовать для вас на постоянной основе. Это означает, что вы сможете продлевать ваш абонемент на протяжении всего года по той же выгодной ставке, с которой начали.
                                </p>
                                <p className="main__choice__modal__layout__content__p">
                                    Мы высоко ценим наших клиентов и стремимся обеспечить вам стабильную и выгодную стоимость в течение всего срока действия абонемента. Наше предложение призвано гарантировать вам сохранение выгодной тарифной ставки на протяжении всего года, что позволит сделать занятия у нас еще более приятными и выгодными.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {selectedProfile === "preSchool" && (
                    <Rates />
                )}
                {selectedProfile === "reading" && (
                    <RatesReading />
                )}
                <img src={wavesKids2} alt="img" className="main__section__waves" />
            </section>
            <section className="main__section" id="feedback">
                <div className="main__section__feedback">
                    <Comments />
                </div>
            </section>
            <section className="main__section" id="teachers">
                <img src={wavesKids} alt="img" className="main__section__waves" />
                <TeacherSlider />
            </section>
            <section className="main__section">
                <DiplomaSlider />
            </section>
            <section className="main__section" id="questions">
                <Questions />
                <img src={wavesKids2} alt="img" className="main__section__waves" />
            </section>
        </main>
    )
}


export default Main;